import logo from './logo.svg';
import './App.css';
import {Route} from "react-router";
import React from "react";
import GenerateQr from "./component/GenerateQr";

function App(props) {
  return (
    <div className="App">
        <Route path='/:qrId'
               render={() => <GenerateQr props={props}/>}/>

    </div>
  );
}

export default App;
